import { Box, SxProps, Theme, Typography, Button } from "@mui/material"
import { DarkLayout } from "components/layouts/DarkLayout"
import { FC, useEffect } from "react"
import { useHistory, useLocation } from "react-router"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { HOME_PATH, SIGNUP_PATH } from "constants/routes"
import { paymentService } from "@recall/payment"
import { BillingPeriod } from "@recall/payment"
import {
    CUSTOM_AUTH_TOKEN_PARAM,
    CUSTOM_BILLING_PERIOD_PARAM,
    RECALL_PRICING_PAGE,
    firebase,
    sentry,
    Spinner,
} from "@recall/common"
import { useQueryParameter } from "hooks/useQueryParameter"
import { toast } from "react-toastify"
import { signInWithCustomToken } from "firebase/auth"

export const RedirectPricingPage: FC = () => {
    const user = useSelector((state: RootState) => state.user)
    const isLoggedIn = Boolean(user?.uid)
    const isPremium = user?.isPremiumUser

    const history = useHistory()
    const location = useLocation()

    const { param: billingPeriod, remove: removeBillingParam } = useQueryParameter(
        CUSTOM_BILLING_PERIOD_PARAM
    )
    const { param: authToken } = useQueryParameter(CUSTOM_AUTH_TOKEN_PARAM)

    const redirectToCheckout = async (uid: string) => {
        removeBillingParam()
        await paymentService.createCheckout(uid, billingPeriod as BillingPeriod)
    }

    const openPricingPage = () => {
        window.open(RECALL_PRICING_PAGE, "_self")
    }

    const loginWithToken = async (authToken: string) => {
        try {
            await signInWithCustomToken(firebase.auth, authToken)
        } catch (e) {
            sentry.captureException(e)
        }
        openPricingPage()
    }

    useEffect(() => {
        if (authToken) {
            loginWithToken(authToken)
        } else if (billingPeriod && isPremium) {
            toast.success("You are already a premium user.")
            history.push(HOME_PATH)
        } else if (billingPeriod && isLoggedIn) {
            redirectToCheckout(user.uid)
        } else if (billingPeriod && !isLoggedIn) {
            history.push(SIGNUP_PATH + location.search)
        } else {
            openPricingPage()
        }
    }, [])

    return (
        <DarkLayout isContained>
            <Box sx={styles.container}>
                <Spinner />
                <Typography variant="h6" textAlign="center">
                    You are being redirected
                </Typography>
                <Box sx={styles.buttonContainer}>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Not being redirected?
                    </Typography>
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={styles.button}
                        onClick={openPricingPage}
                    >
                        Back to pricing page
                    </Button>
                </Box>
            </Box>
        </DarkLayout>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 8,
        px: 4,
        gap: 4,
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 2,
        width: "100%",
    },
    button: {
        color: (theme) => theme.palette.text.primary,
    },
}
