import { useTheme } from "@mui/material"
import React, { useEffect } from "react"
import { Slide, ToastContainer } from "react-toastify"

const RecallToastContainer: React.FC<React.PropsWithChildren> = () => {
    const theme = useTheme()

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--toastify-color-success",
            theme.palette.success.main
        )
        document.documentElement.style.setProperty(
            "--toastify-color-warning",
            theme.palette.warning.main
        )
        document.documentElement.style.setProperty(
            "--toastify-color-error",
            theme.palette.error.main
        )

        document.documentElement.style.setProperty(
            "--toastify-font-family",
            theme.typography.fontFamily
        )
        document.documentElement.style.setProperty(
            "--toastify-text-color-light",
            theme.palette.text.primary
        )
        document.documentElement.style.setProperty(
            "--toastify-text-color-dark",
            theme.palette.text.primary
        )

        // eslint-disable-next-line
    }, [theme.palette.mode, theme.typography.fontFamily, theme.palette.text.primary])

    return (
        <ToastContainer
            position="bottom-center"
            transition={Slide}
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            draggable={true}
            theme={theme.palette.mode}
            toastStyle={{
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[3],
                fontSize: theme.typography.body2.fontSize,
                padding: theme.spacing(1.5, 1.5),
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
            }}
        />
    )
}

export default RecallToastContainer
