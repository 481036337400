import { Database } from "@nozbe/watermelondb"
import {
    HttpClientError,
    ItemModel,
    ItemPartial,
    itemService,
    sentry,
    SummaryLengthEnum,
    toItemPartial,
} from "@recall/common"

import { summariesApi } from "services/api"

const searchWikipedia = async (query: string, language = "en"): Promise<ItemPartial[]> => {
    try {
        const itemApis = await summariesApi.findWikipediaSummary(query, language)
        if (!itemApis) return []
        return itemApis.map((item) => toItemPartial(item)).filter(Boolean)
    } catch (e) {
        sentry.captureException(e)
        return []
    }
}

const getAndSaveWikipedia = async (db: Database, slug: string, language = "en") => {
    try {
        const itemApi = await summariesApi.summarizeWikipediaPage(slug, language)
        if (!itemApi) return null
        const itemModel = await itemService.saveItemApi(db, itemApi, false)
        return itemModel
    } catch (e) {
        sentry.captureException(e)
        return null
    }
}

export interface ApiError {
    code: number
    message: string
}

export interface SaveResult {
    item: ItemModel | null
    error?: ApiError
}

const DEFAULT_ERROR_MESSAGE = "An error occurred while processing your request."

const createApiError = (error: HttpClientError): ApiError => ({
    code: error?.status || 500,
    message: error?.details?.detail || DEFAULT_ERROR_MESSAGE,
})

const getAndSaveScraped = async (
    db: Database,
    url: string,
    language = "en",
    summaryLength: SummaryLengthEnum,
    isImporting = false
): Promise<SaveResult> => {
    try {
        const { data } = await summariesApi.summarizePage(url, summaryLength, {
            language,
            isImporting,
        })

        if (!data) return { item: null }
        const item = await itemService.saveItemApi(db, data, true)
        return { item }
    } catch (err) {
        const error = err as HttpClientError
        return {
            item: null,
            error: createApiError(error),
        }
    }
}

const getAndSavePdf = async (
    db: Database,
    name: string,
    summaryLength: SummaryLengthEnum,
    formData: FormData,
    language = "en"
): Promise<SaveResult> => {
    try {
        const { data } = await summariesApi.summarizePdfFile(null, name, summaryLength, formData, {
            language,
            isSaveInBackgroundAllowed: false,
        })
        if (!data) return { item: null }
        const item = await itemService.saveItemApi(db, data, true)
        return { item }
    } catch (err) {
        const error = err as HttpClientError
        return {
            item: null,
            error: createApiError(error),
        }
    }
}

export const itemAPI = {
    searchWikipedia,
    getAndSaveWikipedia,
    getAndSaveScraped,
    getAndSavePdf,
}
