import { cookiesUtil } from "../utils/cookies"
import { isDevelopment } from "../utils/environment"

export interface UserCookieData {
    uid: string
    email: string | null
    isPremium: boolean
}

const COOKIE_NAME = "__recall_user"
const COOKIE_DOMAIN = isDevelopment() ? "localhost" : ".getrecall.ai"

const set = (data: UserCookieData) => {
    const serializedData = btoa(JSON.stringify(data))
    cookiesUtil.set(COOKIE_NAME, serializedData, {
        domain: COOKIE_DOMAIN,
        secure: true,
        sameSite: "strict",
    })
}

const remove = () => {
    cookiesUtil.remove(COOKIE_NAME, {
        domain: COOKIE_DOMAIN,
    })
}

const get = (): UserCookieData | null => {
    const cookieValue = cookiesUtil.get(COOKIE_NAME)
    if (!cookieValue) return null

    try {
        return JSON.parse(atob(cookieValue))
    } catch {
        return null
    }
}

export const websiteCookieService = {
    get,
    set,
    remove,
}
