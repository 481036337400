import { setupEmulators } from "./services/firebaseService/emulatorConfig"
import { firebase } from "./services/firebaseService/firebaseServices"
import { isDevelopment } from "./utils"

const CONTACT_US_EMAIL = "support@getrecall.ai"
let BASE_API_URL = "https://api.getrecall.ai"
let EXTENSION_ID = "ldbooahljamnocpaahaidnmlgfklbben"
let RECALL_APP_URL = "https://app.getrecall.ai"
const RECALL_WEBSITE_URL = "https://www.getrecall.ai"
const RECALL_PRICING_PAGE = "https://www.getrecall.ai/pricing"
const RECALL_EXTENSION_STORE_URL =
    "https://chrome.google.com/webstore/detail/recall-web-extension/ldbooahljamnocpaahaidnmlgfklbben"
const FIREFOX_RECALL_EXTENSION_STORE_URL =
    "https://addons.mozilla.org/en-US/firefox/addon/getrecall/"
const GTM_CONTAINER_ID = "GTM-MTM4LW76"

if (isDevelopment()) {
    console.log("Setting up development environment")
    BASE_API_URL = "http://localhost:8000"
    RECALL_APP_URL = "http://localhost:3000"
    setupEmulators(
        firebase.auth,
        firebase.firestore,
        firebase.functions,
        firebase.database,
        firebase.storage
    )
}

export {
    BASE_API_URL,
    CONTACT_US_EMAIL,
    EXTENSION_ID,
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    RECALL_APP_URL,
    RECALL_EXTENSION_STORE_URL,
    RECALL_PRICING_PAGE,
    RECALL_WEBSITE_URL,
    GTM_CONTAINER_ID,
}
