export const SIGNUP_EVENT = "Sign Up"
export const SAVE_CARD_EVENT = "Save Card"

export const SUBSCRIBE_FEATURE_CLICKED_EVENT = "Subscribe feature clicked"
export const LIKE_SUBSCRIBE_FEATURE_EVENT = "Like subscribe feature"
export const NEED_SUBSCRIBE_FEATURE_EVENT = "Need subscribe feature"
export const DONT_LIKE_SUBSCRIBE_FEATURE_EVENT = "Don't like subscribe feature"

export const COMPLETE_TUTORIAL_EVENT = "Complete Tutorial"
export const CANCEL_TUTORIAL_EVENT = "Cancel Tutorial"
export const SHARE_CARD_EVENT = "Share Card"
export const SOURCE_CLICKED_EVENT = "Source Clicked"
export const EXPORT_ALL_TO_MARKDOWN = "Export all to markdown"
export const EXPORT_CARD_TO_MARKDOWN = "Export card to markdown"
export const ITEMS_ORDER_BY_EVENT = "Items order by clicked"
export const ITEMS_ORDER_DIRECTION_EVENT = "Items order direction changed"
export const USER_PURCHASED_EVENT = "User purchased"
export const IN_APP_SUMMARY_GENERATED = "In-app summary generated"
export const IN_APP_NO_CARD = "In-app no card"
export const IN_APP_NO_CARD_PDF = "In-app no card - pdf"
export const IN_APP_PDF_TOO_BIG = "PDF size too big"
export const SCHEMA_VERSION_OUTDATED = "Schema version outdated"

// Bookmarks
export const BOOKMARKS_IMPORT_OPEN = "Bookmarks Import Open"
export const BOOKMARKS_CREATE_CARDS = "Bookmarks Create Cards"

// Onboarding
export const ONBOARDING_BANNER_CLICKED = "User Clicked Onboarding Banner"
export const FINISHED_ONBOARDING_STEP = "Finished Onboarding Step"
export const SKIPPED_ONBOARDING_STEP = "Skipped Onboarding Step"
export const STARTED_ONBOARDING_STEP = "Started Onboarding Step"
export const APP_UPGRADE_CLICK = "App upgrade button clicked"

// Surveys
export const USAGE_SURVEY_EVENT = "Usage survey response"

// Question Review
export const GENERATE_QUESTIONS_EVENT = "Generate questions clicked"
export const GENERATE_MORE_QUESTIONS_EVENT = "Generate more questions clicked"
export const REVIEW_CARDS_HELP_EVENT = "Review cards help opened"
export const ANSWER_QUESTION_EVENT = "Answer review question"
export const REVIEW_SESSION_COMPLETED_EVENT = "Review session completed"
export const REVIEW_UNSUBSCRIBE_EVENT = "Review unsubscribe"

export const RANDOM_SEARCH_CLICKED_EVENT = "Random search clicked"

// Extension
export const UNINSTALL_IMPROVEMENT_EVENT = "Extension uninstall improvement"
export const UNINSTALL_EVENT = "Extension uninstalled"
export const REINSTALL_EXTENSION_CLICKED = "Reinstall extension link clicked"

// Referrals
export const INVITE_REFERRAL_BUTTON_CLICKED = "Invite referral button clicked"
export const COUPONS_SECTION_OPENED = "Coupons section opened"
export const CLAIM_COUPON_BUTTON_CLICKED = "Claim coupon button clicked"
export const CLAIM_COUPON_CONFIRM_BUTTON_CLICKED = "Claim coupon confirm button clicked"
export const REFERRALS_LEARN_MORE_CLICKED = "Referrals learn more clicked"
export const SIGNUP_WITH_REFERRAL = "Signup with referral"

// Account
export const USER_DELETED_ACCOUNT = "User deleted account"

// Cards
export const SEARCH_SHORTCUT_USED = "Search shortcut used"
export const CREATE_BLANK_CARD_CLICKED = "Create blank card clicked"
export const IN_APP_PDF_UPLOAD = "In app pdf upload"

// Mobile
export const MOBILE_PRICING_PAGE_OPENED = "Mobile pricing page opened"
