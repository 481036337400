import { deleteDoc, doc, DocumentReference, getDoc, onSnapshot, setDoc } from "firebase/firestore"
import { isEqual } from "lodash"
import { FIRESTORE_COLLECTIONS, tutorials } from "../constants"
import { firebase } from "../services"
import { sentry } from "../utils"

export enum OnboardingStepsEnum {
    CREATE_CARD = "CREATE_CARD",
    CREATE_LINK = "CREATE_LINK",
    INSTALL_EXTENSION = "INSTALL_EXTENSION",
    CREATE_CARD_WITH_EXTENSION = "CREATE_CARD_WITH_EXTENSION",
    CREATE_CARD_IN_APP_SEARCH = "CREATE_CARD_IN_APP_SEARCH",
    CREATE_CARD_WITH_SHARE_TARGET = "CREATE_CARD_WITH_SHARE_TARGET",
    CREATE_ACCOUNT = "CREATE_ACCOUNT",
}

export enum SummaryLengthEnum {
    detailed = "detailed",
    concise = "concise",
}

export interface SummaryOptions {
    language: string
    searchLanguage: string
    defaultLength: SummaryLengthEnum
}

export interface User {
    uid: string
    onboarding: {
        skipped: OnboardingStepsEnum[]
        finished: OnboardingStepsEnum[]
    }
    tutorial: {
        completed: tutorials[]
    }
    usage: {
        extension: number
        scraping: number
        extensionUrls: string[]
        importUrls: string[]
        cost: {
            value: number
            resetDate: number
        }
    }
    extension: {
        options: {
            googleSearchButton: boolean
        }
        autosave?: boolean
    }
    menu: {
        expandedTags: string[]
    }
    surveys: string[]
    dbVersion: string
    modals: string[]
    referralTokenOwned: string
    referralTokenUsed: string
    updatedAt?: number
    summaryOptions?: SummaryOptions
    sharedCards?: string[]
    isReviewEmailEnabled?: boolean
    isOnboardingBannerVisible?: boolean
    schemaVersion?: number
}

export interface UserLocalStorage {
    get: () => Promise<User | null>
    set: (user: User) => Promise<void>
    remove: () => Promise<void>
}

interface GetUserOptions {
    forceFetch?: boolean
}

interface Options {
    alwaysRefetch?: boolean
}

export class UserRepository {
    user: User | null = null
    storage: UserLocalStorage
    options: Options

    constructor(storage: UserLocalStorage, options?: Options) {
        this.storage = storage
        this.options = options || {}
    }

    setUserInStorage = async (user: User) => {
        const storageUser = await this.storage.get()
        if (isEqual(user, storageUser)) return
        this.storage.set(user)
    }

    getUser = async (uid: string, options?: GetUserOptions): Promise<User | null> => {
        try {
            if (this.user && !options?.forceFetch && !this.options?.alwaysRefetch) return this.user

            const userRef = doc(
                firebase.firestore,
                FIRESTORE_COLLECTIONS.USERS,
                uid
            ) as DocumentReference<User>
            const snapshot = await getDoc(userRef)

            const user = { ...(this.user || {}), ...snapshot.data() } as User
            this.user = user

            this.setUserInStorage(user)

            return user
        } catch (e) {
            const err = e as Error
            sentry.captureException(err)
            return await this.storage.get()
        }
    }

    upsertUser = async (uid: string, payload: Partial<User>) => {
        if (!uid) throw new Error("User id must be provided")

        const user = ((await this.getUser(uid)) || {}) as User
        const newUser = { ...user, ...payload }

        if (isEqual(newUser, user)) return

        newUser.updatedAt = Date.now()

        this.user = newUser
        const userRef = doc(firebase.firestore, FIRESTORE_COLLECTIONS.USERS, uid)
        await setDoc(userRef, newUser, { merge: true })
        await this.storage.set(newUser)
    }

    removeUser = () => {
        this.user = null
        this.storage.remove()
    }

    observeUser = (uid: string, callback: (user: User) => any) => {
        const dbVersionRef = doc(firebase.firestore, FIRESTORE_COLLECTIONS.USERS, uid)

        const unsubscribe = onSnapshot(
            dbVersionRef,
            { includeMetadataChanges: true },
            async (snapshot) => {
                const data = snapshot.data()
                if (
                    data?.updatedAt &&
                    this.user?.updatedAt &&
                    data.updatedAt < this.user.updatedAt
                ) {
                    return
                }

                const newUser = { ...(this.user || {}), ...data } as User
                const isUserSame = isEqual(newUser, this.user)

                if (isUserSame) return
                this.user = newUser

                callback(this.user)
            }
        )

        return unsubscribe
    }

    deleteUser = async (uid: string) => {
        const userRef = doc(firebase.firestore, FIRESTORE_COLLECTIONS.USERS, uid)

        await deleteDoc(userRef)
    }
}
