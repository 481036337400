import { useDatabase } from "@nozbe/watermelondb/react"
import {
    expandShortenYouTubeUrl,
    isShortenYouTubeVideo,
    itemRepository,
    posthogService,
    sentry,
    SummaryLengthEnum,
    WIKIPEDIA,
} from "@recall/common"
import {
    IN_APP_NO_CARD,
    IN_APP_NO_CARD_PDF,
    IN_APP_PDF_UPLOAD,
    IN_APP_SUMMARY_GENERATED,
} from "constants/events"
import { useSelector } from "react-redux"
import { summariesApi } from "services/api"
import { itemAPI, SaveResult } from "storage/api/items/Item"
import { RootState } from "storage/redux/rootReducer"

const WIKIPEDIA_URL_REGEX = /https:\/\/.+\.wikipedia\.org\/wiki\/.+/gm
const WIKIPEDIA_SLUG_SEPARATOR = "/wiki/"

const getWikipediaSlug = (url: string) => {
    if (!url.match(WIKIPEDIA_URL_REGEX)) return null

    const urlParts = url.split(WIKIPEDIA_SLUG_SEPARATOR)
    const slug = urlParts?.[urlParts.length - 1]

    return slug || null
}

export type UsageType = "bookmark-import" | "summary"

export const useItemUtils = () => {
    const db = useDatabase()

    const language = useSelector((state: RootState) => state.user.language)
    const summaryLength = useSelector((state: RootState) => state.user.defaultLength)

    const expandShortenUrl = (url: string) => {
        if (isShortenYouTubeVideo(url)) {
            return expandShortenYouTubeUrl(url)
        }

        return url
    }

    const getRealUrl = async (url: string): Promise<string> => {
        try {
            if (url.length >= 30) return url

            const newUrl = await summariesApi.expandSummaryUrl(url)

            if (newUrl) return newUrl

            return url
        } catch (err) {
            sentry.captureException(err, { message: "Failed to expand url", url })
            return url
        }
    }

    const getExistingItem = async (url: string) => {
        let item = await itemRepository.getBySource({ db, sourceIdentifier: url })

        if (item) return item

        const wikipediaSlug = getWikipediaSlug(url)

        if (!wikipediaSlug) return null

        item = await itemRepository.getBySource({
            db,
            sourceIdentifier: wikipediaSlug,
            sourceName: WIKIPEDIA,
        })

        return item || null
    }

    const generateItem = async (
        pageUrl: string,
        type: UsageType = "summary"
    ): Promise<SaveResult> => {
        if (!pageUrl) return null

        const url = await getRealUrl(expandShortenUrl(pageUrl))

        const existingItem = await getExistingItem(url)

        if (existingItem) {
            return { item: existingItem }
        }

        const isImporting = type === "bookmark-import"

        const { item, error } = await itemAPI.getAndSaveScraped(
            db,
            url,
            language,
            summaryLength,
            isImporting
        )

        if (!item) {
            sentry.captureMessage("Failed to scrape item", { url })
            posthogService.captureEvent(IN_APP_NO_CARD, { url, type, error })
            return { item, error }
        }

        posthogService.captureEvent(`${IN_APP_SUMMARY_GENERATED} - ${type}`)

        return { item, error }
    }

    const generateItemFromPdf = async (
        formData: FormData,
        name: string,
        summaryLength: SummaryLengthEnum
    ): Promise<SaveResult> => {
        const { item, error } = await itemAPI.getAndSavePdf(
            db,
            name,
            summaryLength,
            formData,
            language
        )

        if (!item) {
            sentry.captureMessage("Failed to generate card from local pdf")
            posthogService.captureEvent(IN_APP_NO_CARD_PDF, { error })
            return { item, error }
        }

        posthogService.captureEvent(IN_APP_PDF_UPLOAD)
        return { item, error }
    }

    return { generateItem, getExistingItem, generateItemFromPdf }
}
