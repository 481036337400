import { useDatabase } from "@nozbe/watermelondb/react"
import {
    CHECK_USER_PREMIUM_STATE,
    checkExtensionStatus,
    dbUtils,
    firebase,
    premiumUserService,
    sendMessageToExtension,
    SummaryLengthEnum,
    User,
    websiteCookieService,
} from "@recall/common"
import { onAuthStateChanged } from "firebase/auth"
import { useInit } from "hooks/auth/useInit"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { isEmpty } from "lodash"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userRepository } from "repositories/userRepository"
import { referralService } from "services/referralService"
import { SET_APP_LOADING, SET_USER_LOADING } from "storage/redux/app/actionTypes"
import { LOGOUT_ACTION, RootState } from "storage/redux/rootReducer"
import { SET_USER } from "storage/redux/user/actionTypes"
import { initializeUser } from "../../utils/config"
import { useClearOnLogout } from "./useClearOnLogout"
import { useExtensionAuth } from "./useExtensionAuth"
import { summaryUsageService } from "repositories/usageRepository"

export const useAuthenticate = () => {
    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)
    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)
    const { initOnboarding, initTutorials } = useInit()
    const { resetBreadcrumbs } = useBreadcrumbActions()
    const db = useDatabase()
    const extensionAuth = useExtensionAuth()

    const removeAnonymousUserData = async () => {
        await dbUtils.deleteDatabase(db)
    }

    useClearOnLogout()

    useEffect(() => {
        if (uid) {
            loginExtension()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const loginExtension = async () => {
        await extensionAuth.loginWithCustomToken()
        const { isExtensionInstalled, isExtensionLoggedIn } = await checkExtensionStatus()
        if (isExtensionInstalled && isExtensionLoggedIn)
            sendMessageToExtension({
                type: CHECK_USER_PREMIUM_STATE,
                isPremium: isPremiumUser,
            })
    }

    const migrateUserFields = async (user: User, uid: string) => {
        const fields = {}
        if (user?.isReviewEmailEnabled === undefined) fields["isReviewEmailEnabled"] = true
        if (user?.isOnboardingBannerVisible === undefined)
            fields["isOnboardingBannerVisible"] = true

        if (isEmpty(fields)) return

        await userRepository.upsertUser(uid, fields)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebase.auth, async (currentUser) => {
            if (!currentUser) {
                dispatch({ type: LOGOUT_ACTION })
                dispatch({ type: SET_APP_LOADING, payload: true })
                dispatch({ type: SET_USER_LOADING, payload: false })
                userRepository.removeUser()
                extensionAuth.logout()
                websiteCookieService.remove()
                resetBreadcrumbs(false)
                return
            }

            dispatch({ type: SET_USER_LOADING, payload: false })

            const user = await userRepository.getUser(currentUser.uid)

            if (currentUser.emailVerified && user?.referralTokenUsed)
                referralService.verifyReferralUsed(currentUser.uid, user.referralTokenUsed)

            await migrateUserFields(user, currentUser.uid)

            await initializeUser(currentUser)

            const [, completedTutorials] = await Promise.all([
                initOnboarding(currentUser),
                initTutorials(currentUser),
            ])

            const isPremiumUser = await premiumUserService.checkIsPremiumUser(currentUser.uid)

            websiteCookieService.set({
                uid: currentUser.uid,
                email: currentUser.email,
                isPremium: isPremiumUser,
            })

            const isExistingUserLoggedIn = !uid && !isEmpty(user)

            if (isExistingUserLoggedIn) await removeAnonymousUserData()

            const usage = summaryUsageService.getUsageFromUser(user, false)

            dispatch({
                type: SET_USER,
                payload: {
                    uid: currentUser.uid,
                    email: currentUser.email,
                    emailVerified: currentUser.emailVerified,
                    surveys: user?.surveys || null,
                    modals: user?.modals || [],
                    menu: {
                        expandedTags: user?.menu?.expandedTags,
                    },
                    completedTutorials,
                    dbVersion: user?.dbVersion || null,
                    isPremiumUser,
                    language: user?.summaryOptions?.language || "auto",
                    searchLanguage: user?.summaryOptions?.searchLanguage || "en",
                    defaultLength:
                        user?.summaryOptions?.defaultLength || SummaryLengthEnum.detailed,
                    sharedCards: user?.sharedCards || [],
                    isReviewEmailEnabled:
                        user?.isReviewEmailEnabled === undefined
                            ? true
                            : user?.isReviewEmailEnabled,
                    isOnboardingBannerVisible: user?.isOnboardingBannerVisible,
                    usage,
                },
            })
        })

        return unsubscribe

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, db, firebase.auth])
}
