import { collection, getDocs, query } from "@firebase/firestore"
import {
    FIRESTORE_COLLECTIONS,
    Referral,
    firebase,
    posthogService,
    cookiesUtil,
} from "@recall/common"
import { SIGNUP_WITH_REFERRAL } from "constants/events"
import { userRepository } from "repositories/userRepository"
import { functions } from "./firebase/firebaseFunction"

export const REFERRAL_TOKEN = "referral_token"

const generateRandomCode = (length: number = 8): string => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let result = ""

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length)
        result += charset[randomIndex]
    }

    return result
}

const getOrCreateReferralOwned = async (uid: string) => {
    const token = await getReferralOwned(uid)
    if (token) return token

    return await createReferralOwned(uid)
}

const getReferralOwned = async (uid: string) => {
    const user = await userRepository.getUser(uid)
    return user?.referralTokenOwned
}

const createReferralOwned = async (uid: string) => {
    const token = generateRandomCode()
    await userRepository.upsertUser(uid, { referralTokenOwned: token })
    return token
}

const verifyReferralUsed = async (uid: string, token: string) => {
    if (!token) return

    await functions.verifyReferralItem({ token })
    await userRepository.upsertUser(uid, { referralTokenUsed: null })
}

const createReferralUsed = async (uid: string, verified = false) => {
    const token = cookiesUtil.get(REFERRAL_TOKEN)
    if (!token) return

    posthogService.captureEvent(SIGNUP_WITH_REFERRAL)
    await functions.createReferralItem({ token, verified })
    await userRepository.upsertUser(uid, { referralTokenUsed: token })
    cookiesUtil.remove(REFERRAL_TOKEN)
}

const getReferrals = async (uid: string): Promise<Referral[]> => {
    const q = query(
        collection(
            firebase.firestore,
            FIRESTORE_COLLECTIONS.USERS,
            uid,
            FIRESTORE_COLLECTIONS.REFERRALS
        )
    )

    const querySnapshot = await getDocs(q)
    const referrals = querySnapshot.docs.map((doc) => doc.data() as Referral)

    return filterValidReferrals(referrals)
}

const filterValidReferrals = (referrals: Referral[]) => {
    const validReferrals = []
    const referralIPs = new Set()
    const usedCouponsIPS = new Set()

    for (const referral of referrals) {
        if (referral.isClaimed && referral?.ip) {
            usedCouponsIPS.add(referral.ip)
        }
    }

    for (const referral of referrals) {
        if (!referral?.ip || referral?.isGoogleSignup) {
            validReferrals.push(referral)
            continue
        }

        if (referralIPs.has(referral.ip) || usedCouponsIPS.has(referral.ip)) {
            continue
        }

        referralIPs.add(referral.ip)
        validReferrals.push(referral)
    }

    return validReferrals
}

const getCoupons = async () => {
    const { data } = await functions.getCoupons()
    return data
}

export const referralService = {
    getOrCreateReferralOwned,
    verifyReferralUsed,
    createReferralUsed,
    getReferrals,
    getCoupons,
}
