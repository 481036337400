import {
    DEPRECATED_ITEM_PATH,
    HOME_PATH,
    INSTALL_EXTENSION_PATH,
    ITEM_PATH,
    KNOWLEDGE_GRAPH_PATH,
    LOGGING_IN_PATH,
    LOGIN_PATH,
    REDIRECT_PRICING_PATH,
    RESET_PATH,
    REVIEW_UNSUBSCRIBE,
    SAVE_LIB_SUMMARY_PATH,
    SETTINGS_PATH,
    SHARE_ROUTE,
    SHARE_TARGET_PATH,
    SIGNUP_PATH,
    SPACED_REPETITION,
} from "constants/routes"
import { Suspense, lazy, memo } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { THANK_YOU_FOR_INSTALLING_EXTENSION, UNINSTALL_SURVEY_FORM_PATH } from "@recall/common"
import { PrivateRoutes } from "./PrivateRoutes"
import { PublicRoutes } from "./PublicRoutes"
import { RedirectPricingPage } from "components/RedirectPricingPage/RedirectPricingPage"

const UninstallExtensionPage = lazy(
    () => import("../ExtensionPages/UninstallExtensionPage/UninstallExtensionPage")
)

const SharePage = lazy(() => import("../SharePage/SharePage"))

const RoutesComponent = () => {
    const { pathname } = window.location

    return (
        <Suspense>
            <Switch>
                <PublicRoutes
                    path={[SIGNUP_PATH, LOGIN_PATH, RESET_PATH, REVIEW_UNSUBSCRIBE]}
                    exact
                />

                <PrivateRoutes
                    path={[
                        THANK_YOU_FOR_INSTALLING_EXTENSION,
                        INSTALL_EXTENSION_PATH,
                        HOME_PATH,
                        DEPRECATED_ITEM_PATH,
                        ITEM_PATH,
                        SHARE_TARGET_PATH,
                        SAVE_LIB_SUMMARY_PATH,
                        KNOWLEDGE_GRAPH_PATH,
                        SETTINGS_PATH,
                        SPACED_REPETITION,
                        LOGGING_IN_PATH,
                    ]}
                    exact
                />
                <Route
                    path="/http*"
                    render={() => {
                        return <Redirect to={`${SHARE_TARGET_PATH}?url=${pathname.slice(1)}`} />
                    }}
                />
                <Route path={UNINSTALL_SURVEY_FORM_PATH} exact>
                    <UninstallExtensionPage />
                </Route>
                <Route path={REDIRECT_PRICING_PATH} exact>
                    <RedirectPricingPage />
                </Route>
                <Route path={SHARE_ROUTE} exact>
                    <SharePage />
                </Route>
                <Route
                    path="/*"
                    render={() => {
                        return <Redirect to={HOME_PATH} />
                    }}
                />
            </Switch>
        </Suspense>
    )
}

export const Routes = memo(RoutesComponent)
