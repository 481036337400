import { Box, IconButton, SxProps, Theme, Tooltip } from "@mui/material"
import {
    ItemModel,
    SOURCE_SAVED,
    WEBSITE,
    WIKIPEDIA,
    posthogService,
    useOpenInNewTab,
} from "@recall/common"
import crunchbaseIcon from "assets/social/crunchbase.svg"
import githubIcon from "assets/social/github.svg"
import imdbIcon from "assets/social/imdb.svg"
import instagramIcon from "assets/social/instagram.svg"
import mediumIcon from "assets/social/medium.svg"
import twitterIcon from "assets/social/twitter.svg"
import websiteIcon from "assets/social/website.svg"
import wikiIcon from "assets/social/wikipedia.svg"
import youtubeIcon from "assets/social/youtube.svg"
import { useSources } from "components/ItemPage/hooks/useSources"
import { SOURCE_CLICKED_EVENT } from "constants/events"
import { compact } from "lodash"
import { FC, useMemo } from "react"

export const SOURCES_DIV_ID = "id-sources"

interface Props {
    language: string
    item: ItemModel
}

export interface SourceMeta {
    name: string
    display: string
    renderIcon: Function
    formatUrl: (identifier: string, language?: string) => string | null
}

const renderIcon = (icon: string) => {
    return (
        <Box
            component="img"
            src={icon}
            sx={{
                width: "1.2em",
                height: "1.2em",
            }}
        />
    )
}

const SOURCE_META: Record<string, SourceMeta> = {
    wikipedia: {
        name: WIKIPEDIA,
        display: "Wikipedia",
        formatUrl: (identifier: string, language = "en") =>
            `https://${language || "en"}.wikipedia.org/wiki/${identifier}`,
        renderIcon: () => renderIcon(wikiIcon),
    },
    imdb: {
        name: "imdb",
        display: "IMDB",
        formatUrl: (identifier: string) => {
            if (identifier.startsWith("nm")) {
                return `https://www.imdb.com/name/${identifier}`
            } else if (identifier.startsWith("tt")) {
                return `https://www.imdb.com/title/${identifier}`
            }

            return null
        },
        renderIcon: () => renderIcon(imdbIcon),
    },
    medium: {
        name: "medium",
        display: "Medium",
        formatUrl: (identifier: string) => `https://medium.com/@${identifier}`,
        renderIcon: () => renderIcon(mediumIcon),
    },
    website: {
        name: WEBSITE,
        display: "Website",
        formatUrl: (identifier: string) => identifier,
        renderIcon: () => renderIcon(websiteIcon),
    },
    twitter: {
        name: "twitter",
        display: "Twitter",
        formatUrl: (identifier: string) => `https://twitter.com/${identifier}`,
        renderIcon: () => renderIcon(twitterIcon),
    },
    youtube: {
        name: "youtube",
        display: "YouTube",
        formatUrl: (identifier: string) => `https://www.youtube.com/channel/${identifier}`,
        renderIcon: () => renderIcon(youtubeIcon),
    },
    github: {
        name: "github",
        display: "GitHub",
        formatUrl: (identifier: string) => `https://github.com/${identifier}`,
        renderIcon: () => renderIcon(githubIcon),
    },

    instagram: {
        name: "instagram",
        display: "Instagram",
        formatUrl: (identifier: string) => `https://www.instagram.com/${identifier}`,
        renderIcon: () => renderIcon(instagramIcon),
    },
    crunchbase_person: {
        name: "crunchbase_person",
        display: "CrunchBase",
        formatUrl: (identifier: string) => `https://www.crunchbase.com/person/${identifier}`,
        renderIcon: () => renderIcon(crunchbaseIcon),
    },
}

export const getSourceMeta = (sourceName: string): SourceMeta => {
    return SOURCE_META[sourceName]
}

const excludedSources = [SOURCE_SAVED]

export const Sources: FC<Props> = ({ item, language }) => {
    const sources = useSources(item)
    const { openInNewTab } = useOpenInNewTab()

    const itemSources = useMemo(() => {
        const sourcesWithMeta = sources
            .filter((s) => !excludedSources.includes(s.name))
            .map((source) => {
                const sourceMeta = getSourceMeta(source.name)
                if (!sourceMeta) return null

                return {
                    ...sourceMeta,
                    id: source.id,
                    identifier: source.identifier,
                }
            })

        return compact(sourcesWithMeta)
    }, [sources])

    const makeOnClick = (source: SourceMeta, url: string) => () => {
        openInNewTab(url)
        posthogService.captureEvent(SOURCE_CLICKED_EVENT, { source: source.name })
    }

    if (!itemSources.length) return null

    return (
        <Box sx={styles.sources} id={SOURCES_DIV_ID}>
            {itemSources.map((source) => {
                const url = source.formatUrl(source.identifier, language)

                if (!url) return null

                return (
                    <Tooltip
                        key={source.name}
                        title={`Open ${source.display} page`}
                        disableTouchListener
                    >
                        <Box onClick={makeOnClick(source, url)} sx={styles.iconBox}>
                            <IconButton>{source.renderIcon()}</IconButton>
                        </Box>
                    </Tooltip>
                )
            })}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    sources: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 0.5,
    },
    iconBox: {
        textDecoration: "none",
    },
}
