import TreeItem, { treeItemClasses, TreeItemProps } from "@mui/lab/TreeItem"
import { alpha, Checkbox, Link, Theme, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { styled, SxProps } from "@mui/material/styles"
import { Bookmark, checkIsBookmarkUrl, useOpenInNewTab } from "@recall/common"
import { ReactComponent as BookmarkIcon } from "assets/svg/bookmark_icon.svg"
import { ReactComponent as CheckIcon } from "assets/svg/checkbox.svg"
import { orderBy } from "lodash"
import { FC, MouseEvent, useContext, useEffect, useState } from "react"
import { extensionService } from "services/extensionService"
import { TreeContext } from "./BookmarksTree"

type Props = TreeItemProps & {
    bookmark: Bookmark
}

export const BookmarksTreeItem: FC<Props> = ({ label, bookmark, ...other }) => {
    const { selected, addBookmarks, removeBookmarks, isSelected, alreadyAddedBookmarkIds } =
        useContext(TreeContext)

    const isBookmarkSelected = isSelected(bookmark.id)
    const alreadyExists = alreadyAddedBookmarkIds.includes(bookmark.id)
    const [isEveryChildAdded, setIsEveryChildAdded] = useState(false)
    const { openInNewTab } = useOpenInNewTab()

    useEffect(() => {
        if (!bookmark.isDirectory) return

        const bookmarkIds = extensionService.extractBookmarkIds([bookmark])

        const isEveryChildSelected = bookmarkIds
            .filter((id) => id !== bookmark.id && !alreadyAddedBookmarkIds.includes(id))
            .every((bookmarkId) =>
                selected.some((selectedBookmark) => bookmarkId === selectedBookmark.id)
            )

        const isEveryChildAdded = bookmarkIds
            .filter((id) => id !== bookmark.id)
            .every((bookmarkId) => alreadyAddedBookmarkIds.includes(bookmarkId))

        setIsEveryChildAdded(isEveryChildAdded)

        if (isEveryChildSelected && !isBookmarkSelected) addBookmarks([bookmark])
        if (!isEveryChildSelected && isBookmarkSelected) removeBookmarks([bookmark.id])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    const handleCheck = () => {
        if (alreadyExists) return
        if (isBookmarkSelected) {
            removeBookmarks([bookmark.id])
        } else {
            addBookmarks([bookmark])
        }
    }

    const handleOpenBookmark = (e: MouseEvent) => {
        e.stopPropagation()
        openInNewTab(bookmark.url)
    }

    const handleCheckDirectory = (e: MouseEvent<Element>) => {
        e.stopPropagation()

        if (isBookmarkSelected) {
            const bookmarkIds = extensionService.extractBookmarkIds([bookmark])
            removeBookmarks(bookmarkIds)
        } else {
            const bookmarks = extensionService.getChildrenBookmarks([bookmark])
            const bookmarksToAdd = bookmarks.filter(
                (bookmark) => !alreadyAddedBookmarkIds.includes(bookmark.id)
            )
            addBookmarks(bookmarksToAdd)
        }
    }

    if (bookmark.isDirectory && !bookmark?.children?.length) return null

    const orderedBookmarks = orderBy(bookmark?.children, "isDirectory")

    return (
        <StyledTreeItem
            disabled={alreadyExists || isEveryChildAdded}
            label={
                <Box
                    sx={{
                        ...styles.treeItem,
                        cursor: bookmark.isDirectory ? "pointer" : "default",
                    }}
                    onClick={bookmark.isDirectory ? undefined : handleCheck}
                >
                    {bookmark.isDirectory ? (
                        <Typography variant="body2" sx={styles.treeItemLabel}>
                            {label}
                        </Typography>
                    ) : (
                        <Box display="flex" flexGrow={1} sx={{ cursor: "pointer" }}>
                            <Box
                                component={BookmarkIcon}
                                sx={{ path: { fill: (theme) => theme.palette.text.primary } }}
                                mr={1}
                                className="icon"
                            />
                            <Link
                                onClick={handleOpenBookmark}
                                target="_blank"
                                rel="noreferrer"
                                sx={{
                                    fontWeight: "inherit",
                                    color: "text.secondary",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                }}
                            >
                                <Typography variant="body2">{label}</Typography>{" "}
                            </Link>
                        </Box>
                    )}

                    <Checkbox
                        size="medium"
                        checkedIcon={<CheckIcon />}
                        icon={<Box sx={styles.unchecked} />}
                        checked={isBookmarkSelected || alreadyExists}
                        disabled={alreadyExists || isEveryChildAdded}
                        onClick={bookmark.isDirectory ? handleCheckDirectory : handleCheck}
                    />
                </Box>
            }
            {...other}
        >
            {orderedBookmarks.map((bookmark) =>
                bookmark.isDirectory || checkIsBookmarkUrl(bookmark.url) ? (
                    <BookmarksTreeItem
                        className={bookmark.isDirectory ? "directory" : "bookmark"}
                        key={bookmark.id}
                        nodeId={bookmark.id}
                        bookmark={bookmark}
                        label={bookmark.name}
                    />
                ) : null
            )}
        </StyledTreeItem>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    treeItem: { display: "flex", alignItems: "center", p: 0.5, pr: 0 },
    treeItemLabel: { fontWeight: "inherit", flexGrow: 1, color: "text.secondary" },
    unchecked: {
        borderRadius: 0.5,
        width: 19,
        height: 19,
        border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.8)}`,
    },
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    ".icon": {
        fill: theme.palette.text.secondary,
    },
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        "&.Mui-expanded": {
            fontWeight: theme.typography.fontWeightRegular,
        },
        "&:hover": {
            backgroundColor: `${theme.palette.action.hover} !important`,
        },
        "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
            backgroundColor: "transparent",
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: "inherit",
            color: "inherit",
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 14,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
    ".bookmark > div": {
        paddingLeft: "0 !important",
    },
}))
