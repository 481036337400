import { USAGE_LIMIT } from "@recall/common"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { summaryUsageService } from "repositories/usageRepository"
import { RootState } from "storage/redux/rootReducer"

export const useBookmarksImportUsage = () => {
    const [bookmarksImportLeft, setBookmarksImportLeft] = useState(0)
    const uid = useSelector((state: RootState) => state.user.uid)

    const getCardsScrapedCount = async () => {
        const bookmarksImportUsage = await summaryUsageService.getUsage(uid, true)
        setBookmarksImportLeft(USAGE_LIMIT - bookmarksImportUsage)
    }

    useEffect(() => {
        getCardsScrapedCount()
        // eslint-disable-next-line
    }, [uid])

    return bookmarksImportLeft
}
