import { OPEN_URL } from "@recall/common"
import { messagesService } from "../services/messagesService"

export const useOpenInNewTab = () => {
    const isNative = window.isNative || false

    const openInNewTab = (url: string) => {
        if (isNative) {
            messagesService.post(OPEN_URL, url, true)
        } else {
            window.open(url, "_blank")
        }
    }

    return { openInNewTab }
}
