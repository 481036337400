import { UsageChip } from "@recall/common"
import { memo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { useOpenPricingPage } from "hooks/useOpenPricingPage"

export const AppUsageChip = memo(() => {
    const { isPremiumUser, usage } = useSelector((state: RootState) => state.user)
    const { openPricingPage } = useOpenPricingPage()
    if (isPremiumUser) return null

    const handleUpgrade = () => {
        openPricingPage()
    }

    return <UsageChip usage={usage} handleUpgrade={handleUpgrade} />
})
