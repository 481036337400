import { User, UserRepository } from "../../repositories"

export const USAGE_LIMIT = 10

export class SummaryUsageService {
    userRepository: UserRepository

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    isUsageExceeded = async (userId: string, isImporting: boolean) => {
        const summariesGeneratedCount = await this.getUsage(userId, isImporting)
        return summariesGeneratedCount >= USAGE_LIMIT
    }

    getUsage = async (userId: string, isImporting: boolean) => {
        const user = await this.userRepository.getUser(userId)
        if (!user) return 0
        return this.getUsageFromUser(user, isImporting)
    }

    getUsageFromUser = (user: User, isImporting: boolean) => {
        const urls = isImporting ? user?.usage?.importUrls || [] : user?.usage?.extensionUrls || []

        if (urls.length >= 0) {
            return urls.length
        }

        return isImporting ? user?.usage?.scraping || 0 : user?.usage?.extension || 0
    }
}
