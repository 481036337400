import { Auth, connectAuthEmulator } from "firebase/auth"
import { connectDatabaseEmulator, Database } from "firebase/database"
import { connectFirestoreEmulator, Firestore } from "firebase/firestore"
import { connectFunctionsEmulator, Functions } from "firebase/functions"
import { connectStorageEmulator, FirebaseStorage } from "firebase/storage"

export const setupEmulators = (
    auth: Auth,
    firestore: Firestore,
    functions: Functions,
    database: Database,
    storage: FirebaseStorage
) => {
    connectFunctionsEmulator(functions, "localhost", 5001)
    connectAuthEmulator(auth, "http://localhost:5002")
    connectFirestoreEmulator(firestore, "localhost", 5003)
    connectDatabaseEmulator(database, "localhost", 5004)
    connectStorageEmulator(storage, "localhost", 5005)
}
