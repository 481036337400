import {
    CUSTOM_AUTH_TOKEN_PARAM,
    posthogService,
    sentry,
    useOpenInNewTab,
    RECALL_PRICING_PAGE,
    RECALL_APP_URL,
} from "@recall/common"
import { APP_UPGRADE_CLICK, MOBILE_PRICING_PAGE_OPENED } from "constants/events"
import { useIsNative } from "./useIsNative"
import { REDIRECT_PRICING_PATH } from "constants/routes"
import { functions } from "services/firebase/firebaseFunction"

export const useOpenPricingPage = () => {
    const isNative = useIsNative()
    const { openInNewTab } = useOpenInNewTab()

    const getAuthToken = async () => {
        try {
            const { data: token } = await functions.createCustomAuthToken()
            return token
        } catch (e) {
            sentry.captureException(e)
            return ""
        }
    }

    const openPricingPage = async () => {
        if (isNative) {
            const idToken = await getAuthToken()
            const url = `${RECALL_APP_URL}${REDIRECT_PRICING_PATH}?${CUSTOM_AUTH_TOKEN_PARAM}=${idToken}`
            openInNewTab(url)
            posthogService.captureEvent(MOBILE_PRICING_PAGE_OPENED)
        } else {
            openInNewTab(RECALL_PRICING_PAGE)
            posthogService.captureEvent(APP_UPGRADE_CLICK)
        }
    }

    return { openPricingPage }
}
