import { alpha, Box, SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { FC } from "react"
import { USAGE_LIMIT } from "../../services/usage/summaryUsageService"
import { PremiumIcon } from "../icons/PremiumIcon"

interface Props {
    usage: number | undefined
    handleUpgrade: () => void
}

export const UsageChip: FC<Props> = ({ usage, handleUpgrade }) => {
    if (usage === undefined) return null

    return (
        <Tooltip title="This is how many cards you can create before you need to upgrade.">
            <Box sx={styles.count} onClick={handleUpgrade}>
                <PremiumIcon sx={{ fontSize: "1.3rem", marginTop: -0.3 }} />
                <Typography variant="caption" gap={0.3} display="flex">
                    <span>{Math.min(usage, USAGE_LIMIT)}</span>/<span>{USAGE_LIMIT}</span>
                </Typography>
            </Box>
        </Tooltip>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    count: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.6,
        color: (theme) => theme.palette.text.secondary,
        border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.2)}`,
        borderRadius: 1,
        px: 1,
        py: 0.5,
        height: "100%",
        cursor: "pointer",

        "&:hover": {
            background: (theme) => theme.palette.action.hover,
        },
    },
}
