import { Fade, SxProps, Theme } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    Bookmark,
    ItemModel,
    ROOT_TAG_ID,
    Spinner,
    TagModel,
    WEBSITE,
    createAssets,
    posthogService,
    sentry,
    sourceRepository,
    tagRepository,
} from "@recall/common"
import { AddButtonFooter } from "components/layouts/components/Header/AddButton/AddButtonFooter"
import { useOpenOnboarding } from "components/layouts/components/Onboarding/hooks/useOpenOnboarding"
import { BOOKMARKS_CREATE_CARDS } from "constants/events"
import { useUsageStatusUtils } from "hooks/usage/useUsageStatusUtils"
import { useItemUtils } from "hooks/useItemUtils"
import { useShouldInstallExtension } from "hooks/useShouldInstallExtension"
import { uniq } from "lodash"
import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { extensionService } from "services/extensionService"
import { SET_BOOKMARKS_IMPORT_STATE, SET_IMPORT_STATE } from "storage/redux/app/actionTypes"
import { isBookmarksImportComplete, isBookmarksImporting } from "storage/redux/app/selectors"
import { BookmarksImportState, importEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"
import { BookmarksLimitReached } from "./BookmarksLimitReached"
import { BookmarksLoader } from "./BookmarksLoader"
import { BookmarksTree, TreeContext } from "./BookmarksTree"
import { useBookmarksImportUsage } from "./hooks/useBookmarksImportUsage"
import { InstallExtension } from "./InstallExtension"
import { toast } from "react-toastify"

interface Props {
    onClose: () => void
}

const BOOKMARKS_IMPORT_BATCH_LIMIT = 200

export const BookmarksImportTab: FC<Props> = ({ onClose }) => {
    const db = useDatabase()
    const { getExistingItem, generateItem } = useItemUtils()
    const { checkIsBookmarksImportEnabled } = useUsageStatusUtils()
    const { shouldInstallExtension } = useShouldInstallExtension()

    const [isLoading, setIsLoading] = useState(true)
    const [bookmarks, setBookmarks] = useState<Bookmark[]>([])
    const [bookmarksIds, setBookmarkIds] = useState<string[]>([])
    const [alreadyAddedBookmarkIds, setAlreadyAddedBookmarkIds] = useState<string[]>([])

    const [selected, setSelected] = useState<Bookmark[]>([])
    const [isEmpty, setIsEmpty] = useState(false)

    const bookmarksImportState = useSelector((root: RootState) => root.app.bookmarks)
    const isImporting = useSelector(isBookmarksImporting)
    const isImportComplete = useSelector(isBookmarksImportComplete)

    const { closeOnboarding } = useOpenOnboarding()

    const uid = useSelector((state: RootState) => state.user.uid)
    const isPremium = useSelector((state: RootState) => state.user.isPremiumUser)
    const selectedBookmarks = selected.filter((bookmark) => !bookmark.isDirectory)

    const bookmarksImportCardsLeft = useBookmarksImportUsage()

    const freeLimitReached = !isPremium && selectedBookmarks.length > bookmarksImportCardsLeft
    const importLimitReached = isPremium && selectedBookmarks.length >= BOOKMARKS_IMPORT_BATCH_LIMIT

    const dispatch = useDispatch()

    const getBookmarks = async () => {
        try {
            setIsLoading(true)

            const { bookmarks, bookmarksIds } = await extensionService.getPageBookmarks()
            const allBookmarks = extensionService.getChildrenBookmarks(bookmarks)

            const existingCardUrls = new Set(
                (await sourceRepository.getAll(db, WEBSITE)).map((source) => source.identifier)
            )

            const alreadyAddedBookmarkIds = allBookmarks
                .filter((bookmark) => bookmark.url)
                .filter((bookmark) => existingCardUrls.has(bookmark.url))
                .map((bookmark) => bookmark.id)

            setAlreadyAddedBookmarkIds(alreadyAddedBookmarkIds)

            setBookmarkIds(bookmarksIds)
            setBookmarks(bookmarks)
            setIsEmpty(allBookmarks.every((bookmark) => bookmark.isDirectory))
        } catch (error) {
            sentry.captureException(error, { message: "Failed to fetch bookmarks" })
        } finally {
            setIsLoading(false)
        }
    }

    const setItemsCreated = (newItemsCreated: BookmarksImportState) => {
        dispatch({
            type: SET_BOOKMARKS_IMPORT_STATE,
            payload: newItemsCreated,
        })
    }

    useEffect(() => {
        if (selected.length) {
            const bookmarks = selected.filter((bookmark) => !bookmark.isDirectory)
            setItemsCreated({
                existing: 0,
                created: 0,
                failedBookmarks: [],
                count: bookmarks.length,
            })
        }
    }, [selected]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!shouldInstallExtension) getBookmarks()
    }, [shouldInstallExtension]) // eslint-disable-line react-hooks/exhaustive-deps

    const addBookmarks = (bookmarks: Bookmark[]) => {
        setSelected((prev) => uniq([...prev, ...bookmarks]))
    }

    const removeBookmarks = (bookmarkIds: string[]) => {
        setSelected((prev) => prev.filter(({ id }) => !bookmarkIds.includes(id)))
    }

    const isSelected = (id: string) => {
        return selected.some((bookmark) => bookmark.id === id)
    }

    const createCardsForSelectedBookmarks = async () => {
        const bookmarks = selected.filter((bookmark) => !bookmark.isDirectory)
        const itemsCreated = {
            count: bookmarks.length,
            existing: 0,
            created: 0,
            failedBookmarks: [],
        }

        for (let bookmark of bookmarks) {
            if (!bookmark.url) continue

            const existingItem = await getExistingItem(bookmark.url)

            if (existingItem) {
                itemsCreated.existing += 1
                setItemsCreated(itemsCreated)
                continue
            }

            try {
                const isBookmarkImportEnabled = await checkIsBookmarksImportEnabled({
                    openModal: true,
                })

                if (!isBookmarkImportEnabled) return onClose()

                const { item, error } = await generateItem(bookmark.url, "bookmark-import")

                if (error && error?.code === 429) {
                    itemsCreated.failedBookmarks.push({
                        name: bookmark.name,
                        url: bookmark.url,
                    })

                    const remainingBookmarks = bookmarks.slice(bookmarks.indexOf(bookmark) + 1)

                    itemsCreated.failedBookmarks.push(
                        ...remainingBookmarks.map((b) => ({
                            name: b.name,
                            url: b.url,
                        }))
                    )

                    setItemsCreated(itemsCreated)
                    toast(error.message, { type: "error", autoClose: false })
                    break
                }

                if (!item) throw new Error("Failed to fetch bookmark card")

                await assignTagsToItem(item, bookmark)
                await createAssets(db, item, uid)
                itemsCreated.created += 1
                setItemsCreated(itemsCreated)
            } catch {
                sentry.captureMessage("Failed to fetch bookmark card", {
                    url: bookmark?.url,
                })
                itemsCreated.failedBookmarks.push({ name: bookmark.name, url: bookmark.url })
                setItemsCreated(itemsCreated)
                continue
            }
        }
    }

    const assignTagsToItem = async (item: ItemModel, bookmark: Bookmark) => {
        const parents = extensionService.getParentBookmarks(bookmarks, bookmark.id)

        if (!parents.length) return

        const tags: TagModel[] = []
        for (let i = 0; i < parents.length; i++) {
            const parentId = i === 0 ? ROOT_TAG_ID : tags[i - 1].id
            const tagItem = parents.length - 1 === i ? item : null

            const tag = await tagRepository.create({
                db,
                name: parents[i].name,
                item: tagItem,
                isSaved: true,
                parentId,
            })
            tags.push(tag)
        }
    }

    const handleCreateCards = async () => {
        try {
            closeOnboarding()
            dispatch({
                type: SET_IMPORT_STATE,
                payload: importEnum.IMPORTING,
            })
            await createCardsForSelectedBookmarks()
            posthogService.captureEvent(BOOKMARKS_CREATE_CARDS)
        } catch (error) {
            sentry.captureException(error, { message: "Failed to generate card from bookmark" })
        } finally {
            dispatch({
                type: SET_IMPORT_STATE,
                payload: importEnum.IMPORTING_COMPLETE,
            })
        }
    }

    const getTreeComponent = () => {
        if (isLoading)
            return (
                <Box sx={styles.loader}>
                    <Spinner />
                </Box>
            )
        if (isEmpty)
            return (
                <Typography variant="body1" p={2}>
                    We have not found any bookmarks.
                </Typography>
            )

        return <BookmarksTree bookmarks={bookmarks} bookmarksIds={bookmarksIds} />
    }

    if (isImporting || isImportComplete)
        return (
            <BookmarksLoader
                {...bookmarksImportState}
                count={bookmarksImportState.count}
                isLoading={isImporting}
                onClose={onClose}
            />
        )

    return (
        <TreeContext.Provider
            value={{ selected, removeBookmarks, addBookmarks, isSelected, alreadyAddedBookmarkIds }}
        >
            <Box sx={styles.tree}>
                {shouldInstallExtension ? (
                    <InstallExtension />
                ) : (
                    <>
                        <Fade in={!!selectedBookmarks.length}>
                            <Box sx={styles.indicator}>
                                <Typography sx={styles.indicatorText}>
                                    {selectedBookmarks.length}
                                </Typography>
                            </Box>
                        </Fade>
                        {getTreeComponent()}
                    </>
                )}
            </Box>
            <BookmarksLimitReached
                freeLimitReached={freeLimitReached}
                importLimitReached={importLimitReached}
                batchImportLimit={BOOKMARKS_IMPORT_BATCH_LIMIT}
            />
            {!(freeLimitReached || importLimitReached) && (
                <AddButtonFooter
                    isLoading={isImporting}
                    onClick={handleCreateCards}
                    onClose={onClose}
                    okText="Import"
                    disabled={
                        isImporting ||
                        !selectedBookmarks.length ||
                        freeLimitReached ||
                        importLimitReached
                    }
                />
            )}
        </TreeContext.Provider>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    title: {
        textAlign: "left",
    },
    tree: {
        position: "relative",
        height: 336,
        p: 2,
        pt: 3.5,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 1.5,
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    indicator: {
        position: "absolute",
        bottom: 10,
        left: 10,
        width: 20,
        height: 20,
        borderRadius: 12.5,
        backgroundColor: (theme) => theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    indicatorText: {
        color: (theme) =>
            theme.palette.mode === "dark"
                ? theme.palette.text.primary
                : theme.palette.background.paper,
        fontSize: 12,
        fontWeight: "bold",
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
}
