import { Button, ButtonProps } from "@mui/material"
import { PremiumIcon } from "@recall/common"
import { useOpenPricingPage } from "hooks/useOpenPricingPage"
import { FC } from "react"

interface Props extends ButtonProps {
    onclick?: () => void
}

export const UpgradeButton: FC<Props> = ({ onclick, ...props }) => {
    const { openPricingPage } = useOpenPricingPage()

    const handleOnClick = () => {
        if (onclick) onclick()
        openPricingPage()
    }

    return (
        <Button variant="contained" onClick={handleOnClick} startIcon={<PremiumIcon />} {...props}>
            Upgrade
        </Button>
    )
}
