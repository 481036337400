import {
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    OnboardingStepsEnum,
    RECALL_EXTENSION_STORE_URL,
    isFirefox,
    posthogService,
    tutorials,
    useIsMobile,
    useOpenInNewTab,
} from "@recall/common"
import { STARTED_ONBOARDING_STEP } from "constants/events"
import { HOME_PATH, INSTALL_EXTENSION_PATH, SIGNUP_PATH } from "constants/routes"
import { useIsNative } from "hooks/useIsNative"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { SET_IS_ADD_MODAL_OPEN } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL, TUTORIAL_RESTART_SINGLE } from "storage/redux/user/actionTypes"
import { useOpenOnboarding } from "./useOpenOnboarding"

export interface OnboardingSection {
    title: string
    description?: string
    steps: OnboardingStep[]
}

interface OnboardingStep {
    title: string
    completeLabel?: string
    isCompleted: boolean
    isEnabled: boolean
    isSkipped?: boolean
    skippedLabel?: string
    description?: string
    handleComplete?: () => void
    handleSkip?: () => void
}

export const useSteps = () => {
    const onboarding = useSelector((state: RootState) => state.user.onboarding)
    const { closeOnboarding } = useOpenOnboarding()
    const isMobile = useIsMobile()
    const history = useHistory()
    const isNative = useIsNative()

    const dispatch = useDispatch()
    const { openInNewTab } = useOpenInNewTab()

    const isCompleted = useCallback(
        (step: OnboardingStepsEnum): boolean => {
            return onboarding.finished.includes(step)
        },
        [onboarding]
    )

    const makeHandleStart = (step: OnboardingStepsEnum) => () => {
        posthogService.captureEvent(STARTED_ONBOARDING_STEP, { step })
        switch (step) {
            case OnboardingStepsEnum.CREATE_CARD: {
                dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.ITEMS_INTRO })
                dispatch({ type: TUTORIAL_RESTART_SINGLE, payload: tutorials.ITEMS_INTRO })
                closeOnboarding()
                break
            }
            case OnboardingStepsEnum.CREATE_LINK: {
                dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.CREATE_LINK })
                dispatch({ type: TUTORIAL_RESTART_SINGLE, payload: tutorials.CREATE_LINK })
                closeOnboarding()
                break
            }
            case OnboardingStepsEnum.INSTALL_EXTENSION: {
                openInNewTab(
                    !isFirefox() ? RECALL_EXTENSION_STORE_URL : FIREFOX_RECALL_EXTENSION_STORE_URL
                )
                break
            }
            case OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION: {
                history.push(INSTALL_EXTENSION_PATH)
                break
            }
            case OnboardingStepsEnum.CREATE_ACCOUNT: {
                history.push(SIGNUP_PATH)
                break
            }
            case OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH: {
                closeOnboarding()
                history.push(HOME_PATH)
                dispatch({ type: SET_IS_ADD_MODAL_OPEN, payload: true })
                break
            }
            case OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET: {
                history.push(INSTALL_EXTENSION_PATH)
                break
            }
        }
    }

    const onboardingSections: OnboardingSection[] = [
        !isMobile && {
            title: "Extension",
            steps: [
                {
                    title: "Install browser extension",
                    isCompleted: isCompleted(OnboardingStepsEnum.INSTALL_EXTENSION),
                    description:
                        "The Recall Browser Extension lets you save and summarize content from webpages like articles, blogs, YouTube videos, and PDFs.",
                    isEnabled: true,
                    handleComplete: makeHandleStart(OnboardingStepsEnum.INSTALL_EXTENSION),
                },
                {
                    title: `Create a knowledge card with the browser extension`,
                    description:
                        "After installing the extension, you can begin to create summaries of online content. The extension is designed to help you distill the main ideas and facts from extensive content into a condensed form for easier review and organization.",
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION),
                    isEnabled: isCompleted(OnboardingStepsEnum.INSTALL_EXTENSION),
                    handleComplete: makeHandleStart(OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION),
                },
            ],
        },
        {
            title: "Knowledge cards",
            steps: [
                isNative && {
                    title: "Create summary with share intent",
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET),
                    description: "Use share intent to create knowledge cards.",
                    isEnabled: true,
                    handleComplete: makeHandleStart(
                        OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET
                    ),
                },
                {
                    title: "Create knowledge card in-app",
                    description:
                        "Create a knowledge card by pasting a URL into Recall to save and summarize that page as a knowledge card.",
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH),
                    isEnabled: true,
                    handleComplete: makeHandleStart(OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH),
                },
                {
                    title: "Create link between knowledge cards",
                    description:
                        "By linking your knowledge cards, you create a knowledge graph that intelligently surfaces relevant information when needed, enhancing the utility of your knowledge base.",
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_LINK),
                    isEnabled: true,
                    handleComplete: makeHandleStart(OnboardingStepsEnum.CREATE_LINK),
                },
            ],
        },
    ]

    return onboardingSections
        .filter(Boolean)
        .map((section) => ({ ...section, steps: section.steps.filter(Boolean) }))
}
