import Cookies, { CookieAttributes } from "js-cookie"

const get = (name: string) => {
    return Cookies.get(name)
}

const remove = (name: string, options?: CookieAttributes) => {
    Cookies.remove(name, options)
}

const set = (name: string, value: string, options: CookieAttributes) => {
    Cookies.set(name, value, options)
}

export const cookiesUtil = { get, remove, set }
